/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateTypeDto = {
    type: UpdateTypeDto.type;
};
export namespace UpdateTypeDto {
    export enum type {
        WEDDING = 'WEDDING',
        ANNIVERSARY = 'ANNIVERSARY',
        BIRTHDAY = 'BIRTHDAY',
        GRADUATION = 'GRADUATION',
        BABY_SHOWER = 'BABY_SHOWER',
        OTHER = 'OTHER',
    }
}

