/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Gift = {
    id: string;
    updatedAt: string;
    type?: string;
    moment?: Gift.moment;
    senderName?: string;
    receiverName?: string;
    text?: string;
    status: Gift.status;
    muxPlaybackId?: string;
};
export namespace Gift {
    export enum moment {
        BEFORE_UNPACKING = 'BEFORE_UNPACKING',
        AFTER_UNPACKING = 'AFTER_UNPACKING',
    }
    export enum status {
        DELETED = 'DELETED',
        DRAFT = 'DRAFT',
        OPEN = 'OPEN',
        PUBLISHED = 'PUBLISHED',
    }
}

